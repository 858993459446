// These are also used in app/assets/stylesheets/application-redesign/_sizes.sass
import { rem } from 'modules/style/units'
import { unit } from './space'

export const labelWidth = unit(28)
export const mainNavWidth = unit(26)
export const mainNavSkinnyWidth = unit(8)
export const maxContent = unit(104)
export const maxPaddedContent = unit(104 + 4)
export const pageLimitWidth = unit(148)
export const patientMaxWidth = unit(136)
export const sidebar = unit(85)
export const totalColumn = unit(68)

export const labelColumn = rem(85) // legacy
